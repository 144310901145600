import { graphql } from 'gatsby';
import { OutboundLink } from '../utils/analytics';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Layout } from '../components/Layout';
import { SocialIcons } from '../constants';
import { useReveal } from '../hooks/useReveal';
import { Team } from '../types';
import { SEO } from '../components/SEO';

interface TeamPageProps {
  data: {
    allTeamJson: {
      nodes: Team[];
    };
  };
}

const TeamPage = ({ data }: TeamPageProps) => {
  useReveal();

  return (
    <Layout>
      <SEO title="Team" />
      <div className="container mx-auto py-10 md:py-20 px-4 md:w-10/12 xl:w-7/12 reveal fade-bottom">
        <h5 className="font-display text-5xl md:text-6xl lg:text-10xl">
          <span className="font-display-alt">Te</span>am
        </h5>

        <p className="mt-3 md:mt-6 opacity-70">
          DevFest for Ukraine is brought to you with love, passion, and a
          combined effort by Ukrainian GDG Groups and{' '}
          <OutboundLink
            href="https://youcontrol.com.ua/en/catalog/company_details/41339527/"
            target="_blank"
            className="underline underline-offset-4"
            rel="noreferrer"
          >
            GDev NGO
          </OutboundLink>
          .
          <br />
          <br />
          So what is GDG exactly? GDG stands for{' '}
          <OutboundLink
            href="https://developers.google.com/community/gdg"
            className="underline underline-offset-4"
            target="_blank"
          >
            Google Developer Group
          </OutboundLink>{' '}
          - a voluntary community of like-minded people passionate about tech.
          For many years, we’ve organized meetups, hackathons, and programming
          mentorship related to technologies like Android, Angular, and Google
          Cloud. For regular users, Google products like Gmail are just handy
          services, but not for us. Developers see much more: APIs, scalability
          issues, complex technology stacks, etc. And that is what GDG is about!
          In 2017 the impact of our community was expanding so fast that we had
          to register it as an NGO called{' '}
          <OutboundLink
            href="https://youcontrol.com.ua/en/catalog/company_details/41339527/"
            target="_blank"
            className="underline underline-offset-4"
            rel="noreferrer"
          >
            GDev
          </OutboundLink>
          . We’ve organized many tech events including a conference for 1000
          attendees (remember the times before corona? :) and inspired thousands
          of software developers, QAs, and managers around the world.
          <br />
          <br />
          Ever wondered what it takes to make a big charitable tech conference?
          Here it is: {data.allTeamJson.nodes.length} people in 8 cities in 5
          different countries working outside of the office hours, 0 full-time
          workers, and sometimes lots of compromises :) If you’re curious about
          what’s under the hood, check out our{' '}
          <OutboundLink
            href="https://medium.com/gdg-lviv"
            target="_blank"
            className="underline underline-offset-4"
            rel="noreferrer"
          >
            team blog
          </OutboundLink>
          , where we share a lot of tips, confessions, and lessons learned.
        </p>

        <div className="mt-16 md:mt-28 grid gap-x-6 gap-y-10 grid-cols-2 md:grid-cols-3">
          {data.allTeamJson.nodes.map(
            ({ name, image, group, social }, index) => (
              <OutboundLink
                href={social.url}
                key={index}
                className="group"
                target="_blank"
              >
                <div className="flex flex-col items-center">
                  <div className="relative">
                    <div className="w-[120px] h-[120px] lg:w-[160px] lg:h-[160px] xl:w-[180px] xl:h-[180px] rounded-full overflow-hidden will-change-[transform]">
                      <GatsbyImage
                        className="md:group-hover:scale-110 transition-transform duration-500 rounded-full overflow-hidden"
                        image={getImage(image)}
                        alt={name}
                        objectFit="cover"
                      />
                    </div>

                    <span
                      className={`h-7 w-7 lg:h-9 lg:w-9 xl:h-10 xl:w-10 p-1 lg:p-2 flex items-center justify-center absolute bottom-0 right-0 -translate-y-1/4 -translate-x-1/4 rounded-full border bg-neutral-900 md:group-hover:scale-90 transition-transform duration-500 ${
                        index % 2
                          ? 'border-primary fill-primary'
                          : 'border-secondary fill-secondary'
                      }`}
                    >
                      {SocialIcons[social.type].svg}
                    </span>
                  </div>
                  <div className="mt-5 flex items-center justify-center">
                    <h5 className="text-center text-xl md:text-2xl">{name}</h5>
                  </div>
                  <p className="text-center opacity-70 md:group-hover:opacity-100 transition-opacity duration-500">
                    {group}
                  </p>
                </div>
              </OutboundLink>
            )
          )}
        </div>
      </div>
    </Layout>
  );
};

export default TeamPage;

export const pageQuery = graphql`
  query {
    allTeamJson {
      nodes {
        name
        group
        social {
          type
          url
        }
        image {
          childImageSharp {
            gatsbyImageData(
              width: 400
              quality: 80
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
